<template>
  <div class="box-options">
    <div class="d-none d-md-flex options-product-detail">
      <div
        v-for="(item, index) in options"
        :key="index"
        class="option-detail"
        :class="optionSelect == index ? 'select-option' : ''"
      >
        <p @click="optionSelect = index">{{ item.nameOption }}</p>
      </div>
    </div>
    <div v-if="!isMovil" class="d-flex flex-wrap">
      <div v-if="optionSelect == 0" class="w-100">
        <carousel-products
          v-if="productRelated && productRelated.length > 0"
          :title="'Productos relacionados'"
          :products-analytics="productsAnalyticsRelated"
          :products="productRelated"
          :position="'carousel detalle producto'"
          class="pt-0"
        />
      </div>
      <stock-store
        v-if="optionSelect == 1 && dataProduct"
        :data-product="dataProduct"
        :size="size"
        :color="color"
        @changeSize="$emit('changeSize', $event)"
        @changeColor="$emit('changeColor', $event)"
      />
      <opinions-rating
        v-if="optionSelect == 2"
        :opinions="dataProduct.valoraciones"
      />
      <shipping v-if="optionSelect == 3" />
      <wash-and-care
        v-if="optionSelect == 4"
        :data-wash="dataProduct.lavado_cuidado"
        :text-data-wash="dataProduct.lavado"
      />
    </div>
    <div v-else class="d-block options-product-detail-movil">
      <div>
        <div
          class="option-product-detail-movil"
          @click="showOutfitProductDetailMovil = !showOutfitProductDetailMovil"
        >
          <p
            :style="
              showOutfitProductDetailMovil
                ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
                : 'margin-bottom: 0;'
            "
          >
            Completa tu outfit
          </p>
          <img
            v-if="showOutfitProductDetailMovil"
            src="@/assets/icons/arrow-up.svg"
            alt="arrow up"
          />
          <img v-else src="@/assets/icons/arrow-down.svg" alt="arrow down" />
        </div>
        <carousel-products
          v-if="
            productRelated &&
              showOutfitProductDetailMovil &&
              productRelated.length > 0
          "
          :title="'Productos relacionados'"
          :products-analytics="productsAnalyticsRelated"
          :products="productRelated"
          :position="'carousel detalle producto'"
          class="pt-0"
        />
        <div
          class="option-product-detail-movil"
          @click="
            showStockStoreMovil
              ? (showStockStoreMovil = false)
              : (showStockStoreMovil = true)
          "
        >
          <p
            :style="
              showStockStoreMovil
                ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
                : 'margin-bottom: 0;'
            "
          >
            Disponibilidad en tienda
          </p>
          <img
            v-if="showStockStoreMovil"
            src="@/assets/icons/arrow-up.svg"
            alt="arrow up"
          />
          <img v-else src="@/assets/icons/arrow-down.svg" alt="arrow down" />
        </div>
        <stock-store
          v-if="showStockStoreMovil && dataProduct"
          :data-product="dataProduct"
          :size="size"
          :color="color"
          @changeSize="$emit('changeSize', $event)"
          @changeColor="$emit('changeColor', $event)"
        />
        <div
          class="option-product-detail-movil"
          @click="
            showOpinionsRatingMovil
              ? (showOpinionsRatingMovil = false)
              : (showOpinionsRatingMovil = true)
          "
        >
          <p
            :style="
              showOpinionsRatingMovil
                ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
                : 'margin-bottom: 0;'
            "
          >
            Opiniones y valoraciones
          </p>
          <img
            v-if="showOpinionsRatingMovil"
            src="@/assets/icons/arrow-up.svg"
            alt="arrow up"
          />
          <img v-else src="@/assets/icons/arrow-down.svg" alt="arrow down" />
        </div>
        <opinions-rating
          v-if="showOpinionsRatingMovil"
          :opinions="dataProduct.valoraciones"
        />

        <div
          class="option-product-detail-movil"
          @click="
            showShippingMovil
              ? (showShippingMovil = false)
              : (showShippingMovil = true)
          "
        >
          <p
            :style="
              showShippingMovil
                ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
                : 'margin-bottom: 0;'
            "
          >
            Envío y garantía
          </p>
          <img
            v-if="showShippingMovil"
            src="@/assets/icons/arrow-up.svg"
            alt="arrow up"
          />
          <img v-else src="@/assets/icons/arrow-down.svg" alt="arrow down" />
        </div>
        <shipping v-if="showShippingMovil" />

        <div
          class="option-product-detail-movil"
          @click="
            showWashAndCareMovil
              ? (showWashAndCareMovil = false)
              : (showWashAndCareMovil = true)
          "
        >
          <p
            :style="
              showWashAndCareMovil
                ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
                : 'margin-bottom: 0;'
            "
          >
            Lavado y cuidados
          </p>
          <img
            v-if="showWashAndCareMovil"
            src="@/assets/icons/arrow-up.svg"
            alt="arrow up"
          />
          <img v-else src="@/assets/icons/arrow-down.svg" alt="arrow down" />
        </div>
        <wash-and-care
          v-if="showWashAndCareMovil"
          :data-wash="dataProduct.lavado_cuidado"
          :text-data-wash="dataProduct.lavado"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StockStore from '@/components/ProductDetail/Options/StockStore.vue'
import Shipping from '@/components/ProductDetail/Options/Shipping.vue'
import OpinionsRating from '@/components/ProductDetail/Options/OpinionsRating.vue'
import WashAndCare from '@/components/ProductDetail/Options/WashAndCare.vue'
import CarouselProducts from '@/components/Templates/CarouselProducts.vue'

import { mapState } from 'vuex'
export default {
  components: {
    StockStore,
    Shipping,
    OpinionsRating,
    WashAndCare,
    CarouselProducts,
  },
  props: {
    dataProduct: {
      type: Object,
      required: true,
      default: () => {},
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      optionSelect: 0,
      optionSelectMovil: 0,
      showOutfitProductDetailMovil: true,
      showStockStoreMovil: false,
      showOpinionsRatingMovil: false,
      showShippingMovil: false,
      showWashAndCareMovil: false,
      options: [
        { nameOption: 'Completa tu outfit', value: 0 },
        { nameOption: 'Disponibilidad en tienda', value: 1 },
        { nameOption: 'Opiniones y valoraciones', value: 2 },
        { nameOption: 'Envío y garantía', value: 3 },
        { nameOption: 'Lavado y cuidado', value: 4 },
      ],
    }
  },
  computed: {
    ...mapState(['innerWidth']),
    ...mapState('products', [
      'product',
      'outfitProduct',
      'outfitAnalitics',
      'productRelated',
      'productsAnalyticsRelated',
    ]),
    isMovil() {
      return this.innerWidth < 768
    },
  },
  methods: {
    dataLayerPush(evento, productos, lugar) {
      window.dataLayer.push({
        event: evento,
        productoPadre: this.$route.params.product,
        productoPadreSKU: this.$route.params.product.split('-')[0],
        currencyCode: 'COP',
        productos: productos,
        lugar: lugar,
      })
    },
  },
}
</script>
