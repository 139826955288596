<template><div class="message-errors" v-html="msgError"></div></template>
<script>
export default {
  name: 'MessageErrors',
  props: {
    msgError: {
      type: String,
      required: false,
      default: 'Mensaje',
    },
    type: {
      type: String,
      required: false,
      default: 'info', // Hay 3 tipos, info, warning, error, success
    },
    bg: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
<style scoped>
.message-errors {
  font-size: 0.88rem;
  color: red !important;
  text-align: center;
  font-weight: 300;
  position: absolute;
  width: 100%;
  margin-top: 1rem;
  padding-right: 8rem;
}
@media (max-width: 1200px) {
  .message-errors {
    padding-right: 2rem;
  }
}
</style>
