import { render, staticRenderFns } from "./MessageErrors.vue?vue&type=template&id=e63d70fc&scoped=true&"
import script from "./MessageErrors.vue?vue&type=script&lang=js&"
export * from "./MessageErrors.vue?vue&type=script&lang=js&"
import style0 from "./MessageErrors.vue?vue&type=style&index=0&id=e63d70fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e63d70fc",
  null
  
)

export default component.exports