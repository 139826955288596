<template>
  <div>
    <div v-if="isDesktop" class="d-flex container-max-width">
      <div class="img-skeleton-product-detail">
        <b-skeleton-img aspect="8:9"></b-skeleton-img>
        <b-skeleton-img aspect="8:9"></b-skeleton-img>
        <b-skeleton-img aspect="8:9"></b-skeleton-img>
      </div>
      <div class="detail-skeleton-product-detail">
        <b-skeleton class="title-product-detail"></b-skeleton>
        <div class="d-flex">
          <b-skeleton class="sku-product-detail"></b-skeleton>
          <b-form-rating
            v-model="rating"
            class="raiting-product-detail-skeleton"
            no-border
            readonly
          ></b-form-rating>
          <b-skeleton class="raiting-value-product-detail"></b-skeleton>
        </div>
        <b-skeleton class="price-product-detail"></b-skeleton>
        <b-skeleton class="cupo-product-detail"></b-skeleton>
        <b-skeleton class="selects-product-detail"></b-skeleton>
        <div class="d-flex mb-5">
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-guide-product-detail"></b-skeleton>
        </div>
        <b-skeleton class="selects-product-detail"></b-skeleton>
        <div class="d-flex" style="margin-bottom: 2.5rem">
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
        </div>
        <div class="d-flex mb-5">
          <b-skeleton class="favorite-product-detail"></b-skeleton>
          <b-skeleton class="agg-product-detail"></b-skeleton>
        </div>
        <b-skeleton></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton></b-skeleton>
      </div>
    </div>
    <div v-else>
      <b-skeleton-img width="100%" aspect="8:9"></b-skeleton-img>
      <div class="skeleton-product-detail-movil">
        <b-skeleton class="skeleton-title-product-detail-movil"></b-skeleton>
        <b-skeleton class="skeleton-price-product-detail-movil"></b-skeleton>
        <b-skeleton class="skeleton-cupo-product-detail-movil"></b-skeleton>
        <b-skeleton width="50%" class="selects-product-detail"></b-skeleton>
        <div class="d-flex" style="margin-bottom: 2.5rem">
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
        </div>
        <b-skeleton width="50%" class="selects-product-detail"></b-skeleton>
        <div class="d-flex" style="margin-bottom: 1.5rem">
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
          <b-skeleton class="sizes-product-detail"></b-skeleton>
        </div>
        <div class="skeleton-btn-agg-product-movil">
          <b-skeleton width="100%" class="skeleton-btn-agg-movil"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
export default {
  components: { BSkeletonImg, BSkeleton },
  data() {
    return {
      rating: 5.0,
      isDesktop: true,
    }
  },
  created() {
    if (window.screen.width < 767) {
      this.isDesktop = false
    }
  },
}
</script>
