<template>
  <div class="shipping-container">
    <calc-shipping class="col-12 col-md-4" />
    <div class="info-shipping">
      <p class="info-shipping-title">DEVOLUCIONES GRATIS:</p>
      <p class="info-shipping-subtitle">¿CÓMO PUEDO DEVOLVER MI PEDIDO?</p>
      <p>
        Nota: No aceptamos devoluciones de top deportivo, traje de baño, bodys,
        short de triatlón, calcetines y máscaras.
      </p>
      <p class="info-shipping-subtitle">
        ANTES DE CONTACTARNOS TEN LISTA LA SIGUIENTE INFORMACIÓN:
      </p>
      <p>
        1. Verifica que el producto esté nuevo, sin uso, con su caja y/o
        envoltorio original, y con sus etiquetas sin haber sido desprendidas
      </p>
      <p>
        2. Ten listo el Número de Pedido / Orden que encuentras en tu correo de
        confirmación
      </p>
      <p>3. Consulta la disponibilidad del producto en nuestra página web</p>
      <p>
        Si deseas cambiar la talla, verifica la disponibilidad de talla en
        nuestra página web, ten listo el código de producto y la talla que
        deseas
      </p>
    </div>
  </div>
</template>
<script>
import CalcShipping from '@/components/Templates/CalcShipping.vue'
export default {
  components: { CalcShipping },
}
</script>
