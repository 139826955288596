<template>
  <div class="stock-store-container">
    <p>
      Consulta la disponibilidad del producto en nuestras tiendas a nivel
      nacional.<br />
      <b>Nota:</b> Esta información es orientativa y corresponde a cálculos
      realizados en la madrugada de hoy, no representa compromiso de separación
      de productos. Los precios y promociones marcadas como exclusivo online no
      aplican en tiendas físicas.
    </p>
    <div
      v-if="refProduct.habilitado && dataProduct.publicado == 1"
      class="box-stock"
    >
      <div class="select-size-stock">
        <div class="title-box-stock">
          <h3>1.</h3>
          <p class="ps-2">Selecciona una talla</p>
        </div>
        <div class="d-flex">
          <div
            v-for="(sizeProduct, index) in refProduct.tallas"
            :key="index"
            class="d-flex pe-3"
          >
            <span
              v-if="sizeProduct.stock > 0"
              class="sizes-product"
              :class="sizeProduct.id == size ? 'size-selected' : ''"
              @click="$emit('changeSize', sizeProduct.id)"
              >{{ sizeProduct.name.replace('Única', 'UN') }}</span
            >
          </div>
        </div>
        <!-- <div class="guide-sizes-stock" @click="$store.state.showSizeGuide = true">
                    <button type="">Guía de tallas</button>
                </div> -->
      </div>
      <div class="select-color-stock">
        <div class="title-box-stock">
          <h3>2.</h3>
          <p class="ps-2">Selecciona un color</p>
        </div>
        <div class="d-flex">
          <div
            v-for="(colorProduct, index) in dataProduct.referencias"
            :key="index"
            class="d-flex pe-2 mb-2"
          >
            <div
              class="contenedor-colors-product-detail"
              :class="
                colorProduct.color_id == color
                  ? 'color-selected-product-detail'
                  : ''
              "
              @click="$emit('changeColor', colorProduct.color_id)"
            >
              <div
                class="colors-product-detail"
                :style="
                  colorProduct.color_texturap != ''
                    ? 'background-image: url(' +
                      colorProduct.color_textura +
                      ')'
                    : 'background-color:' + colorProduct.color_hx
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="select-city-stock">
        <div class="title-box-stock">
          <h3>3.</h3>
          <p class="ps-2">Selecciona una ciudad</p>
        </div>
        <div>
          <select
            v-model="selectCity"
            class="form-select"
            :disabled="!color && !size"
            aria-label="Selección de ciudad"
          >
            <option value="">Selecciona una ciudad</option>
            <option
              v-for="(item, index) in stockCitys"
              :key="index"
              :value="item.value"
              >{{ item.text }}</option
            >
          </select>
        </div>
      </div>
    </div>
    <div v-else class="mb-5">
      <div
        v-if="refProduct.habilitado"
        class="d-flex flex-wrap align-items-center"
      >
        <h4 class="w-100 text-center mb-2">¡Exclusivo Online!</h4>
        <p class="text-center my-0">
          Este producto solo está disponible en nuestra tienda online.
        </p>
      </div>
      <div
        v-else
        class="d-flex flex-wrap align-items-center justify-content-center"
      >
        <h4 class="w-100 text-center mb-2">¡Producto agotado!</h4>
        <p class="text-center my-0">
          Este producto no está disponible :( pero seguro encontrarás algo que
          te guste.
        </p>
      </div>
    </div>
    <div v-if="productStock" class="result-citys-stock">
      <div
        v-for="(item, index) in productStock"
        :key="index"
        class="col-12 col-md-4 col-xl-3 citys-stock"
      >
        <p class="title-city-stock">{{ item.nombre }}</p>
        <p>{{ item.direccion }}</p>
        <p>{{ item.telefono }}</p>
        <p v-if="item.stock <= 3" class="alert-stock">
          ¡Pocas unidades disponibles!
        </p>
        <a target="_blank" :href="item.bodega_mapa">Ver en mapa</a>
      </div>
    </div>
    <div v-if="loadingGetSize" class="d-flex justify-content-center mb-4">
      Cargando...
      <div class="loader" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectCity: '',
      loadingGetSize: false,
    }
  },
  computed: {
    ...mapState('products', ['stockCitys', 'productStock']),
    refProduct() {
      return this.dataProduct.referencias.find(e => e.color_id == this.color)
    },
    globalRating() {
      let total = 0.0
      for (var i = 0; i < this.dataProduct.valoraciones.length; i++) {
        total += parseFloat(this.dataProduct.valoraciones[i].puntaje)
      }
      return (
        (total + 225) /
        (this.dataProduct.valoraciones.length + 50)
      ).toFixed(1)
    },
  },
  watch: {
    async selectCity() {
      this.loadingGetSize = true
      await this.postShippingProduct({
        color: this.color,
        talla: this.size,
        sku: this.dataProduct.producto_sku,
        city: this.selectCity,
      }).then(() => {
        this.loadingGetSize = false
      })
    },
    async color() {
      if (this.selectCity) {
        this.loadingGetSize = true
        await this.postShippingProduct({
          color: this.color,
          talla: this.size,
          sku: this.dataProduct.producto_sku,
          city: this.selectCity,
        }).then(() => {
          this.loadingGetSize = false
        })
      }
    },
    async size() {
      if (this.selectCity && this.size) {
        this.loadingGetSize = true
        await this.postShippingProduct({
          color: this.color,
          talla: this.size,
          sku: this.dataProduct.producto_sku,
          city: this.selectCity,
        }).then(() => {
          this.loadingGetSize = false
        })
      } else {
        await this.postShippingProduct({
          color: this.color,
          talla: this.size,
          sku: this.dataProduct.producto_sku,
        })
      }
    },
  },
  async mounted() {
    if (this.size && this.color) {
      await this.postShippingProduct({
        color: this.color,
        talla: this.size,
        sku: this.dataProduct.producto_sku,
        city: this.selectCity,
      }).then(() => {
        this.loadingGetSize = false
      })
    }
  },
  methods: {
    ...mapActions('products', ['postShippingProduct']),
  },
}
</script>
