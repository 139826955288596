var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-options"},[_c('div',{staticClass:"d-none d-md-flex options-product-detail"},_vm._l((_vm.options),function(item,index){return _c('div',{key:index,staticClass:"option-detail",class:_vm.optionSelect == index ? 'select-option' : ''},[_c('p',{on:{"click":function($event){_vm.optionSelect = index}}},[_vm._v(_vm._s(item.nameOption))])])}),0),(!_vm.isMovil)?_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.optionSelect == 0)?_c('div',{staticClass:"w-100"},[(_vm.productRelated && _vm.productRelated.length > 0)?_c('carousel-products',{staticClass:"pt-0",attrs:{"title":'Productos relacionados',"products-analytics":_vm.productsAnalyticsRelated,"products":_vm.productRelated,"position":'carousel detalle producto'}}):_vm._e()],1):_vm._e(),(_vm.optionSelect == 1 && _vm.dataProduct)?_c('stock-store',{attrs:{"data-product":_vm.dataProduct,"size":_vm.size,"color":_vm.color},on:{"changeSize":function($event){return _vm.$emit('changeSize', $event)},"changeColor":function($event){return _vm.$emit('changeColor', $event)}}}):_vm._e(),(_vm.optionSelect == 2)?_c('opinions-rating',{attrs:{"opinions":_vm.dataProduct.valoraciones}}):_vm._e(),(_vm.optionSelect == 3)?_c('shipping'):_vm._e(),(_vm.optionSelect == 4)?_c('wash-and-care',{attrs:{"data-wash":_vm.dataProduct.lavado_cuidado,"text-data-wash":_vm.dataProduct.lavado}}):_vm._e()],1):_c('div',{staticClass:"d-block options-product-detail-movil"},[_c('div',[_c('div',{staticClass:"option-product-detail-movil",on:{"click":function($event){_vm.showOutfitProductDetailMovil = !_vm.showOutfitProductDetailMovil}}},[_c('p',{style:(_vm.showOutfitProductDetailMovil
              ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
              : 'margin-bottom: 0;')},[_vm._v(" Completa tu outfit ")]),(_vm.showOutfitProductDetailMovil)?_c('img',{attrs:{"src":require("@/assets/icons/arrow-up.svg"),"alt":"arrow up"}}):_c('img',{attrs:{"src":require("@/assets/icons/arrow-down.svg"),"alt":"arrow down"}})]),(
          _vm.productRelated &&
            _vm.showOutfitProductDetailMovil &&
            _vm.productRelated.length > 0
        )?_c('carousel-products',{staticClass:"pt-0",attrs:{"title":'Productos relacionados',"products-analytics":_vm.productsAnalyticsRelated,"products":_vm.productRelated,"position":'carousel detalle producto'}}):_vm._e(),_c('div',{staticClass:"option-product-detail-movil",on:{"click":function($event){_vm.showStockStoreMovil
            ? (_vm.showStockStoreMovil = false)
            : (_vm.showStockStoreMovil = true)}}},[_c('p',{style:(_vm.showStockStoreMovil
              ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
              : 'margin-bottom: 0;')},[_vm._v(" Disponibilidad en tienda ")]),(_vm.showStockStoreMovil)?_c('img',{attrs:{"src":require("@/assets/icons/arrow-up.svg"),"alt":"arrow up"}}):_c('img',{attrs:{"src":require("@/assets/icons/arrow-down.svg"),"alt":"arrow down"}})]),(_vm.showStockStoreMovil && _vm.dataProduct)?_c('stock-store',{attrs:{"data-product":_vm.dataProduct,"size":_vm.size,"color":_vm.color},on:{"changeSize":function($event){return _vm.$emit('changeSize', $event)},"changeColor":function($event){return _vm.$emit('changeColor', $event)}}}):_vm._e(),_c('div',{staticClass:"option-product-detail-movil",on:{"click":function($event){_vm.showOpinionsRatingMovil
            ? (_vm.showOpinionsRatingMovil = false)
            : (_vm.showOpinionsRatingMovil = true)}}},[_c('p',{style:(_vm.showOpinionsRatingMovil
              ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
              : 'margin-bottom: 0;')},[_vm._v(" Opiniones y valoraciones ")]),(_vm.showOpinionsRatingMovil)?_c('img',{attrs:{"src":require("@/assets/icons/arrow-up.svg"),"alt":"arrow up"}}):_c('img',{attrs:{"src":require("@/assets/icons/arrow-down.svg"),"alt":"arrow down"}})]),(_vm.showOpinionsRatingMovil)?_c('opinions-rating',{attrs:{"opinions":_vm.dataProduct.valoraciones}}):_vm._e(),_c('div',{staticClass:"option-product-detail-movil",on:{"click":function($event){_vm.showShippingMovil
            ? (_vm.showShippingMovil = false)
            : (_vm.showShippingMovil = true)}}},[_c('p',{style:(_vm.showShippingMovil
              ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
              : 'margin-bottom: 0;')},[_vm._v(" Envío y garantía ")]),(_vm.showShippingMovil)?_c('img',{attrs:{"src":require("@/assets/icons/arrow-up.svg"),"alt":"arrow up"}}):_c('img',{attrs:{"src":require("@/assets/icons/arrow-down.svg"),"alt":"arrow down"}})]),(_vm.showShippingMovil)?_c('shipping'):_vm._e(),_c('div',{staticClass:"option-product-detail-movil",on:{"click":function($event){_vm.showWashAndCareMovil
            ? (_vm.showWashAndCareMovil = false)
            : (_vm.showWashAndCareMovil = true)}}},[_c('p',{style:(_vm.showWashAndCareMovil
              ? 'border-bottom: 3px solid #654062; margin-bottom: 0; font-family: `PT Sans Bold`, sans-serif;'
              : 'margin-bottom: 0;')},[_vm._v(" Lavado y cuidados ")]),(_vm.showWashAndCareMovil)?_c('img',{attrs:{"src":require("@/assets/icons/arrow-up.svg"),"alt":"arrow up"}}):_c('img',{attrs:{"src":require("@/assets/icons/arrow-down.svg"),"alt":"arrow down"}})]),(_vm.showWashAndCareMovil)?_c('wash-and-care',{attrs:{"data-wash":_vm.dataProduct.lavado_cuidado,"text-data-wash":_vm.dataProduct.lavado}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }