<template>
  <div class="rating-content">
    <div class="rating-detail">
      <div class="rating-global">
        <b-form-rating
          v-model="globalRating"
          readonly
          show-value
          no-border
          inline
          variant="warning"
          precision="1"
        ></b-form-rating>
        <p class="mb-1">
          {{ opinions.length }}
          {{ opinions.length > 1 ? 'reseñas' : 'reseña' }}
        </p>
      </div>
      <div id="rating-calc">
        <div
          v-for="(element, index) in rateNumber"
          :key="index"
          class="rating-calc-content"
        >
          <div class="rate-calc">
            <p>{{ element.rate }}</p>
            <div class="bar-rate">
              <div
                :id="'bar-percent' + element"
                class="bar-percent"
                :style="'width:' + element.percentRate"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="add-comment">
        <div
          class="add-comment-content"
          @click="
            $router.push({
              name: 'AddComment',
              params: { url: dataProduct.url.replace('product-', '') },
            })
          "
        >
          <p>¿Compraste este producto?</p>
          <p class="bold">Escribe una opinión</p>
        </div>
      </div>
      <p class="add-comment-text">
        Los clientes escriben voluntariamente las valoraciones, se pueden leer
        mientras el producto está disponible. No se realiza ninguna compensación
        económica a cambio. Revisamos todos los mensajes.
      </p>
    </div>
    <div class="comments-raiting">
      <div v-if="opinions.length > 0">
        <div v-for="(item, index) in currentPageItems" :key="index">
          <div class="comment">
            <div class="title-comment">
              <strong>{{ item.nombre_usuario }}</strong>
              <b-form-rating
                v-model="item.puntaje"
                class="p-0 ms-2 mb-1"
                readonly
                show-value
                no-border
                inline
                variant="warning"
                precision="1"
              ></b-form-rating>
            </div>
            <p>{{ item.comentario }}</p>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-items-center justify-content-center h-100"
      >
        <div class="text-center">
          <h5 style="color: #654062">
            Este producto aún no cuenta con valoraciones.
          </h5>
          <h5 style="color: #654062">
            !Sé el primero en valorar este producto!
          </h5>
        </div>
      </div>
      <b-pagination
        v-if="opinions.length > 4"
        v-model="currentPage"
        class="pagination-comment"
        :total-rows="rateNumber.length - 1"
        :per-page="perPage"
        aria-controls="products-table"
        align="center"
        :hide-goto-end-buttons="true"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import { BPagination } from 'bootstrap-vue'
import { mapState } from 'vuex'
export default {
  components: { BPagination },
  props: {
    opinions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 4,
      rateNumber: [
        { rate: '5', numRate: 25, percentRate: '50%' },
        { rate: '4', numRate: 22, percentRate: '22.5%' },
        { rate: '3', numRate: 3, percentRate: '2.5%' },
        { rate: '2', numRate: 0, percentRate: '0%' },
        { rate: '1', numRate: 0, percentRate: '0%' },
      ],
    }
  },
  computed: {
    ...mapState('products', ['dataProduct']),
    globalRating() {
      let total = 0.0
      for (var i = 0; i < this.opinions.length; i++) {
        total += parseFloat(this.opinions[i].puntaje)
      }
      return (5 * 25 + 4 * 22 + 3 * 3 + total) / (this.opinions.length + 50)
    },
    currentPageItems() {
      return this.opinions.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      )
    },
  },
  created() {
    const elThis = this
    this.opinions.forEach(function(opinion) {
      let rate = opinion.puntaje
      let findRate = elThis.rateNumber.find(element => element.rate == rate)
      let index = elThis.rateNumber.indexOf(findRate)
      elThis.rateNumber[index].numRate += 1
      elThis.rateNumber[index].percentRate =
        (elThis.rateNumber[index].numRate / (elThis.opinions.length + 50)) *
          100 +
        '%;'
    })
    this.rateNumber = elThis.rateNumber
  },
}
</script>
