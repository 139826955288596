<template>
  <div
    :id="'size-guide-product-detail-' + typeScreem"
    class="size-guide-product-detail"
  >
    <div
      class="container-sizes-guide-product-detail"
      @click="
        ''


      "
    >
      <div class="container-description-size-guide">
        <h2 class="title-size-guide">
          {{
            typeOfGarment == 'Zapatos'
              ? typeOfGarment
              : 'Prendas ' + typeOfGarment + 'es'
          }}
        </h2>
        <p class="description-size-guide">
          <strong>{{ getGuideSize.titleDescription }}</strong> <br /><br />
          <span
            v-for="(data, index) in getGuideSize.description"
            :key="index"
            class="mb-0"
          >
            <strong>{{ index + 1 }}. </strong> {{ data }} <br />
          </span>
        </p>
      </div>
      <div class="table-sizes-product-detail">
        <b-table striped hover borderless :items="getGuideSize.sizes"></b-table>
      </div>
      <div class="w-100 d-none d-md-flex justify-content-center mb-5 pb-5">
        <button
          class="btn-show-video-size-guide"
          @click="toggleVideo('video_size_product_detail')"
        >
          <img src="@/assets/icons/play-video.svg" />Mira como medir
        </button>
      </div>
      <div class="w-100 d-flex d-md-none justify-content-center mb-5 pb-5">
        <button
          class="btn-show-video-size-guide"
          @click="toggleVideo('video_size_product_detail_movil')"
        >
          <img src="@/assets/icons/play-video.svg" />Mira como medir
        </button>
      </div>
    </div>
    <img
      class="btn-close-modal-size-guide"
      src="@/assets/icons/delete-gray-icon.svg"
      alt="boton cerrar"
      @click="$emit('closeSizeGuide', false)"
    />
    <div
      class="background-modal-size-guide"
      @click="$emit('closeSizeGuide', false)"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    typeOfGarment: {
      type: String,
      required: false,
      default: '',
    },
    typeScreem: {
      type: String,
      required: false,
      default: 'other',
    },
  },
  data() {
    return {
      loadingGetSizes: false,
    }
  },
  computed: {
    ...mapState('products', ['dataSizes', 'dataProduct']),
    getGuideSize() {
      let sizes = this.dataSizes.find(e => e.garmentType == this.typeOfGarment)
      return sizes
    },
  },
  methods: {
    toggleVideo(id_video) {
      let video = document.getElementById(id_video)
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen()
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen()
      }
      video.play()
      video.currentTime = 0
      video.controls = true
      this.listenerExitVideo(id_video)
    },
    async listenerExitVideo(id_video) {
      let video = document.getElementById(id_video)
      await setTimeout(() => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitCurrentFullScreenElement
        ) {
          this.listenerExitVideo(id_video)
        } else {
          video.pause()
        }
      }, 1000)
    },
  },
}
</script>
