<template>
  <div class="content-wash-and-care">
    <p class="text-center w-100 fs-6">
      {{ textDataWash.toLowerCase().replace(/^\w/, d => d.toUpperCase()) }}
    </p>
    <div class="ms-auto mt-3 me-auto" v-html="dataWash" />
  </div>
</template>
<script>
export default {
  props: {
    dataWash: {
      type: String,
      required: false,
      default: '',
    },
    textDataWash: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
